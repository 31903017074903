<template>
  <div id="app">
    <h1>Söderhamn Golf och Shuffleboard</h1>

    <image-slider />

    <p>
      Siten är under uppbyggnad, kika in snart för mer information om Söderhamns
      nya favoritställe!
    </p>
    <p>
      För mer information om bokning, kontakta Dennis på
      <a href="tel:+46702331430">070 233 14 30</a> eller maila till
      <a href="mailto:soderhamngolfochshuffle@gmail.com"
        >soderhamngolfochshuffle@gmail.com</a
      >
    </p>

    <!-- <static-map /> -->
  </div>
</template>

<script>
import ImageSlider from "./components/ImageSlider";
// import StaticMap from "./components/Map";

export default {
  name: "App",

  components: {
    ImageSlider,
    // StaticMap,
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
