<template>
  <div class="wrapper">
    <div class="image image-1"></div>
    <div class="image image-2"></div>
    <div class="image image-3"></div>
    <div class="image image-4"></div>
  </div>
</template>

<script>
export default {
  name: "ImageSlider",
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.image {
  height: 200px;
  width: 280px;
  border-radius: 5px;
  background-position: center;
  background-size: cover;
  margin: 12px;
  background-repeat: no-repeat;

  @media (max-width: 768px) {
    margin: 3px;
  }
}

.image-1 {
  background-image: url("../assets/img/jd_1-min.jpg");
}

.image-2 {
  background-image: url("../assets/img/jd_2-min.jpg");
}

.image-3 {
  background-image: url("../assets/img/jd_3-min.jpg");
}

.image-4 {
  background-image: url("../assets/img/jd_4-min.jpg");
}
</style>
